<template>
	<div class="payment-logo-wrapper">
		<img class="payment-logo" :alt="customLogo" :src="require('../../assets/payment-icons/' + customLogo)" />
	</div>
</template>

<script>
export default {
	name: 'PaymentLogo',
	props: {
		customLogo: String,
	},
};
</script>

<style>
.payment-logo-wrapper {
	margin: 1.5rem 0 0.5rem;
}
.payment-logo {
	height: 80px;
	object-fit: contain;
	width: 100%;
}
</style>
